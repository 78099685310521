// import { Button, Col, Menu, Row } from "antd";
import { message, Input, Button, Typography } from "antd";
import Icon from "@ant-design/icons";
import "antd/dist/antd.css";
// import {
//   //   useBalance,
//   //   useContractLoader,
//   //   useContractReader,
//   //   useGasPrice,
//   //   useOnBlock,
//   useUserProviderAndSigner,
// } from "eth-hooks";
// import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import React, { useCallback, useEffect, useState } from "react";
//import { Link, Route, Switch, useLocation } from "react-router-dom";
import "./App.css";
import {
  Account,
  LoginButtons,
  // Contract,
  // Faucet,
  // GasGauge,
  // Header,
  // Ramp,
  ThemeSwitch,
  // NetworkDisplay,
  // FaucetHint,
  // NetworkSwitch,
} from "./components";
import { NETWORKS, ALCHEMY_KEY } from "./constants";
import Tween from "rc-tween-one";
import { useThemeSwitcher } from "react-css-theme-switcher";
// import externalContracts from "./contracts/external_contracts";
// contracts
// import deployedContracts from "./contracts/hardhat_contracts.json";
import { Transactor, Web3ModalSetup } from "./helpers";
// import { Home, ExampleUI, Hints, Subgraph } from "./views";
import { useStaticJsonRPC } from "./hooks";
import { ReactComponent as DiscordIcon } from "./img/discord.svg";
import { ReactComponent as TwitterIcon } from "./img/twitter.svg";
import { ReactComponent as MetamaskIcon } from "./img/metamask.svg";
import { ReactComponent as BattlesText } from "./img/battles_text.svg";
import SvgDrawPlugin from "rc-tween-one/lib/plugin/SvgDrawPlugin";
Tween.plugins.push(SvgDrawPlugin);

const { ethers } = require("ethers");
const { Text, Title, Paragraph } = Typography;
/*
    Welcome to 🏗 scaffold-eth !

    Code:
    https://github.com/scaffold-eth/scaffold-eth

    Support:
    https://t.me/joinchat/KByvmRe5wkR-8F_zz6AjpA
    or DM @austingriffith on twitter or telegram

    You should get your own Alchemy.com & Infura.io ID and put it in `constants.js`
    (this is your connection to the main Ethereum network for ENS etc.)


    🌏 EXTERNAL CONTRACTS:
    You can also bring in contract artifacts in `constants.js`
    (and then use the `useExternalContractLoader()` hook!)
*/

/// 📡 What chain are your contracts deployed to?
const initialNetwork = NETWORKS.polygon; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = false;
// const NETWORKCHECK = true;
const USE_BURNER_WALLET = false; // toggle burner wallet feature
// const USE_NETWORK_SELECTOR = false;

const web3Modal = Web3ModalSetup();

// 🛰 providers
const providers = [
  "https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406",
  `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  "https://rpc.scaffoldeth.io:48544",
];

const urlQuery = new URLSearchParams(window.location.search);
const fragment = new URLSearchParams(window.location.hash.slice(1));
const isDiscord = urlQuery.get("from") === "discord" && fragment.get("access_token");

const SecondStep = props => {
  const { address } = props;
  return (
    <>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, y: -10, delay: 200 }]}
        style={{ opacity: 1, marginBottom: 10, display: "flex" }}
      >
        <div style={{ margin: 10, display: "flex", alignItems: "center" }}>
          <Icon component={MetamaskIcon} style={{ fontSize: 24, marginRight: 10 }} />
          <b>
            {address.slice(0, 5)}...{address.slice(-5)}
          </b>
        </div>
      </Tween>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, y: -10 }]}
        style={{ opacity: 1, display: "inline-block" }}
      >
        <Paragraph>
          <Title level={4}>Nice to know you! You're one step closer!</Title>
          Second, connect your Discord account.
        </Paragraph>
      </Tween>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, y: 10 }]}
        style={{ opacity: 1, display: "inline-block" }}
      >
        <Button href={process.env.REACT_APP_DISCORD_URL} size="large">
          CONNECT DISCORD
        </Button>
      </Tween>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, delay: 1000, y: 10 }]}
        style={{ opacity: 1, display: "inline-block", maxWidth: 600, marginTop: 20 }}
      >
        <Paragraph>
          This allows us to pair your address with your Discord account, which helps us prevent botting and users using
          multiple wallets.
        </Paragraph>
      </Tween>
    </>
  );
};

const ThirdStep = props => {
  const { userData, address, refreshToken, getUserData } = props;

  const handleTwitterAuth = () => {
    try {
      refreshToken().then(res => {
        let at = res.at;
        let requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json", Authorization: "Bearer " + at },
        };
        let url = process.env.REACT_APP_API_URL + "/twitter/auth";

        fetch(url, requestOptions)
          .then(res => res.json())
          .then(
            async result => {
              if (result && result.error) {
                message.error(result.error);
                return;
              }
              if (result && result.url) {
                window.location = result.url;
              }
              //if (process.env.REACT_APP_DEBUG) console.log(result)
            },
            error => {
              console.log(error);
            },
          );
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, y: -10, delay: 200 }]}
        style={{ opacity: 1, marginBottom: 10, display: "flex" }}
      >
        <div style={{ margin: 10, display: "flex", alignItems: "center" }}>
          <Icon component={MetamaskIcon} style={{ fontSize: 24, marginRight: 10 }} />
          <b>
            {address.slice(0, 5)}...{address.slice(-5)}
          </b>
        </div>
        <div style={{ margin: 10, display: "flex", alignItems: "center" }}>
          <Icon component={DiscordIcon} style={{ fontSize: 24, marginRight: 10 }} />
          <b>{userData.discord.username + "#" + userData.discord.discriminator}</b>
        </div>
      </Tween>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, y: 5, delay: 200 }]}
        style={{ opacity: 1, display: "inline-block" }}
      >
        <Paragraph>
          Now follow{" "}
          <a href="https://twitter.com/PlayNFTBattles" target="_blank" rel="noreferrer noopener">
            @PlayNFTBattles
          </a>{" "}
          on Twitter. Don't forget to turn the notifications on!
        </Paragraph>
      </Tween>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, y: 10, delay: 500 }]}
        style={{ opacity: 1, display: "inline-block" }}
      >
        <Button onClick={() => window.open("https://twitter.com/PlayNFTBattles", "_blank")} size="large">
          <Icon component={TwitterIcon} style={{ fontSize: 20, position: "relative", top: 2 }} /> FOLLOW @PLAYNFTBATTLES
        </Button>
      </Tween>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, y: 10, delay: 1000 }]}
        style={{ opacity: 1, display: "inline-block" }}
      >
        <br />
        <Paragraph style={{ marginTop: 10 }}>Once you are ready, connect your Twitter account.</Paragraph>
        <Button onClick={handleTwitterAuth} size="large">
          <Icon component={TwitterIcon} style={{ fontSize: 20, position: "relative", top: 2 }} /> CONNECT TWITTER
        </Button>
      </Tween>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, delay: 1500, y: 10 }]}
        style={{ opacity: 1, display: "inline-block", maxWidth: 600, marginTop: 20 }}
      >
        <Paragraph>
          This only gives us read access to verify you're following us! (And also avoid those pesky bots)
        </Paragraph>
      </Tween>
    </>
  );
};

const Ready = props => {
  const { userData, address, refreshToken } = props;

  const handleDiscordInvite = () => {
    try {
      refreshToken().then(res => {
        let at = res.at;
        let requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json", Authorization: "Bearer " + at },
        };
        let url = process.env.REACT_APP_API_URL + "/discordinvite";

        fetch(url, requestOptions)
          .then(res => res.json())
          .then(
            async result => {
              if (result && result.error) {
                message.error(result.error);
                return;
              }
              if (result && result.url) {
                window.location = result.url;
              }
              //if (process.env.REACT_APP_DEBUG) console.log(result)
            },
            error => {
              console.log(error);
            },
          );
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, y: -10, delay: 200 }]}
        style={{ opacity: 1, marginBottom: 10, display: "flex" }}
      >
        <div style={{ margin: 10, display: "flex", alignItems: "center" }}>
          <Icon component={MetamaskIcon} style={{ fontSize: 24, marginRight: 10 }} />
          <b>
            {address.slice(0, 5)}...{address.slice(-5)}
          </b>
        </div>
        <div style={{ margin: 10, display: "flex", alignItems: "center" }}>
          <Icon component={DiscordIcon} style={{ fontSize: 24, marginRight: 10 }} />
          <b>{userData.discord.username + "#" + userData.discord.discriminator}</b>
        </div>
        <div style={{ margin: 10, display: "flex", alignItems: "center" }}>
          <Icon component={TwitterIcon} style={{ fontSize: 24, marginRight: 10 }} />
          <b>@{userData.twitter.username}</b>
        </div>
      </Tween>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, y: 10, delay: 1000 }]}
        style={{ opacity: 1, display: "inline-block" }}
      >
        <Paragraph style={{ marginTop: 10 }}>
          <Title>Awesome! You are ready!</Title>
          Join Discord and <b>/battle</b> to continue the journey!
          <br />
          Good luck, battler!
        </Paragraph>
      </Tween>
      <Tween
        animation={[{ type: "from", opacity: 0, duration: 1000, y: -10, delay: 1500 }]}
        style={{ opacity: 1, display: "inline-block" }}
      >
        <Button onClick={handleDiscordInvite} size="large">
          <Icon component={DiscordIcon} style={{ fontSize: 20, position: "relative", top: 2 }} /> JOIN DISCORD
        </Button>
      </Tween>
    </>
  );
};

const FirstStep = props => {
  const { accessToken, userData, logoutOfWeb3Modal, loadWeb3Modal, handleLogin, handleLogout, userSigner, address } =
    props;

  return !userSigner || !accessToken ? (
    <>
      <Tween animation={[{ type: "from", opacity: 0, duration: 1000 }]} style={{ opacity: 1, display: "inline-block" }}>
        <Paragraph>
          <Title level={5}>To gain access to the battlefield you need to complete a few simple steps</Title>
          First, <u>connect your wallet and sign a message to log in.</u>
        </Paragraph>
      </Tween>
      <div style={{ display: "flex" }}>
        <LoginButtons
          web3Modal={web3Modal}
          loadWeb3Modal={loadWeb3Modal}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
          accessToken={accessToken}
          handleLogin={handleLogin}
          userData={userData}
          handleLogout={handleLogout}
        />
      </div>
      {address && (
        <Tween
          animation={[{ type: "from", opacity: 0, duration: 1000, delay: 1000, y: 5 }]}
          style={{ opacity: 1, display: "inline-block" }}
        >
          <Paragraph>
            <div style={{ margin: 10 }}>
              We only do this to verify you own wallet{" "}
              <b>
                {address.slice(0, 5)}...{address.slice(-5)}
              </b>
              <br />
              and doesn't give us permission to do anything with it.
            </div>
          </Paragraph>
        </Tween>
      )}
    </>
  ) : (
    <div></div>
  );
};

const Hello = React.memo(props => {
  const { userData, userSigner, address, mainnetProvider } = props;
  const [animation, setAnim] = useState();

  React.useEffect(() => {
    const mouseMove = e => {
      const x = e.clientX;
      const innerWidth = window.innerWidth;
      setAnim({
        x: (-1 * (innerWidth - x * 2)) / 4,
        duration: 2000,
        ease: "easeOutQuad",
      });
    };
    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, [setAnim]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        letterSpacing: 5,
      }}
    >
      <Tween animation={animation}>
        <Title>
          <Tween
            animation={[{ type: "from", y: 0, opacity: 0, duration: 1000 }]}
            style={{ opacity: 1, display: "inline-block" }}
          >
            <span>Hello&nbsp;</span>
          </Tween>
          <Tween
            animation={[{ type: "from", y: 0, opacity: 0, duration: 1000, delay: 300 }]}
            style={{ opacity: 1, display: "inline-block" }}
          >
            <span style={{ color: "gold" }}>
              <Account
                address={address}
                userSigner={userSigner}
                mainnetProvider={mainnetProvider}
                web3Modal={web3Modal}
                userData={userData}
              />
            </span>
          </Tween>
        </Title>
      </Tween>
    </div>
  );
});

const dataStartArr = [0, "100%", "100% 50%", "100%", "75% 0%"];
let i = 0;

const BattlesLogo = () => {
  const [tweenData, setTweenData] = useState("100%");
  const { currentTheme } = useThemeSwitcher();

  // const handleLogoClick = () => {
  //   setTweenData(dataStartArr[i]);
  //   i++;
  //   i = i >= dataStartArr.length ? 0 : i;
  // };

  // useEffect(() => {
  //   setInterval(handleLogoClick, 5000);
  // }, []);

  return (
    <svg style={{ margin: 20, width: 200, height: "auto" }} viewBox={"0 0 800 800"}>
      <g>
        <Tween
          animation={[{ SVGDraw: tweenData, duration: 3000 }]}
          d="M710.26,399.37v1.13c-0.02,15.03-1.02,29.88-2.9,44.45c-2.99,23.14-8.31,45.62-15.73,67.31c-8.01,23.48-18.48,45.99-31.11,67.31c-7.59,12.76-15.92,25.08-25.04,36.92c-8.05,10.51-16.7,20.67-25.86,30.39c-14.96,15.83-31.33,30.56-48.91,44.02c-10.81,8.25-22.08,16.03-33.77,23.29c-13.16,8.2-26.84,15.71-41.02,22.54c-23.67,11.36-48.69,20.78-74.78,28c-24.09,6.67-49.08,11.46-74.78,14.15c-16.35,1.72-32.99,2.62-49.85,2.62c-16.87,0-33.52-0.9-49.86-2.62l-14.37-2.72L89.74,571.94c2.79,2.61,5.65,5.15,8.62,7.63c18.83,16.02,40.22,29.66,63.5,40.39c23.22,10.69,48.34,18.47,74.79,22.83c16.18,2.69,32.82,4.09,49.86,4.09c17.02,0,33.67-1.4,49.85-4.09c26.45-4.36,51.57-12.14,74.78-22.83c23.29-10.73,44.68-24.37,63.51-40.39c3.9-3.27,7.64-6.63,11.27-10.15c17.81-16.95,32.96-36.19,44.87-57.16c1.93-3.39,3.77-6.83,5.51-10.31L142.74,399.97l0.14-0.03l-0.14-0.04L536.3,297.92c-1.74-3.48-3.58-6.92-5.51-10.31c-11.91-20.97-27.06-40.21-44.87-57.16c-3.63-3.52-7.37-6.88-11.27-10.15c-18.83-16.02-40.22-29.66-63.51-40.39c-23.21-10.69-48.33-18.47-74.78-22.83c-16.18-2.69-32.83-4.09-49.85-4.09c-17.04,0-33.68,1.4-49.86,4.09c-26.45,4.36-51.57,12.14-74.79,22.83c-23.28,10.73-44.67,24.37-63.5,40.39c-2.97,2.48-5.83,5.02-8.62,7.63L222.28,23.71l14.37-2.72c16.34-1.72,32.99-2.62,49.86-2.62c16.86,0,33.5,0.9,49.85,2.62c25.7,2.69,50.69,7.48,74.78,14.15c26.09,7.22,51.11,16.64,74.78,28c14.18,6.83,27.86,14.34,41.02,22.54c11.69,7.26,22.96,15.04,33.77,23.29c17.58,13.46,33.95,28.19,48.91,44.02c9.16,9.72,17.81,19.88,25.86,30.39c9.12,11.84,17.45,24.16,25.04,36.92c12.63,21.32,23.1,43.83,31.11,67.31c7.42,21.69,12.74,44.17,15.73,67.31C709.24,369.49,710.24,384.34,710.26,399.37z"
          component="path"
          height="800"
          width="800"
          viewBox={"0 0 800 800"}
          style={{
            fill: currentTheme === "dark" ? "#FFF" : "#222",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </g>
    </svg>
  );
};

function App(props) {
  // specify all the chains your app is available on. Eg: ['localhost', 'mainnet', ...otherNetworks ]
  // reference './constants.js' for other networks
  const networkOptions = [initialNetwork.name, "mainnet", "rinkeby"];

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  const [userData, setUserData] = useState();
  const [accessToken, setAccessToken] = useState(localStorage.getItem("hello:auth"));
  const [loading, setLoading] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);
  const { currentTheme } = useThemeSwitcher();

  // load all your providers
  const mainnetProvider = useStaticJsonRPC(providers);

  if (DEBUG) console.log(`Using ${selectedNetwork} network`);

  // 🛰 providers
  if (DEBUG) console.log("📡 Connecting to Mainnet Ethereum");

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  // const price = useExchangeEthPrice(targetNetwork, mainnetProvider);

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  // const gasPrice = useGasPrice(targetNetwork, "fast");
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  //const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider, USE_BURNER_WALLET);
  const userSigner = injectedProvider && injectedProvider.getSigner();

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  useEffect(() => {
    if (address && accessToken && !userData) getUserData();
    if (isDiscord && address && userData && (!userData.discord || !userData.discord.id)) {
      try {
        refreshToken().then(res => {
          let at = res.at;
          let requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + at },
          };
          let url = process.env.REACT_APP_API_URL + "/linkDiscord/" + isDiscord;

          fetch(url, requestOptions)
            .then(res => res.json())
            .then(
              result => {
                if (result && !result.error) {
                  let u = { ...userData };
                  u.discord = result;
                  setUserData(u);
                } else {
                  if (result.error) {
                    console.log("link discord", result.error || "no result");
                    message.error(result.error);
                  }
                }
                //if (process.env.REACT_APP_DEBUG) console.log(result)
              },
              error => {
                console.log(error);
              },
            );
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, [address, accessToken, userData]);

  async function getUserData() {
    if (address) {
      refreshToken().then(async result => {
        let at = result.at;
        try {
          let requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + at },
          };
          return await fetch(process.env.REACT_APP_API_URL + "/getUserData", requestOptions)
            .then(res => res.json())
            .then(
              result => {
                if (result && !result.error) {
                  setUserData(result);
                  return result;
                }
                return { error: result?.error || "not found" };
              },
              err => {
                return { error: err };
              },
            );
        } catch (err) {
          return { error: err };
        }
      });
    }
  }

  const refreshToken = () => {
    const at = localStorage.getItem("hello:auth");
    const exp = localStorage.getItem("hello:exp");
    return new Promise((resolve, reject) => {
      //if (!at || !exp) { return reject({error: "no access token"}); }
      if (exp > Date.now()) return resolve({ at: at });

      fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + at },
        credentials: "include",
      })
        .then(response => response.json())
        .then(result => {
          if (result.at && result.exp) {
            localStorage.setItem("hello:auth", String(result.at));
            localStorage.setItem("hello:exp", String(result.exp));
            setAccessToken(result.at);
            return resolve({ at: String(result.at) });
          } else {
            return reject({ error: result });
          }
        })
        .catch(err => {
          return reject({ error: err });
        });
    });
  };

  const handleLogin = () => {
    return new Promise((resolve, reject) => {
      if (!address) {
        return reject({ error: "user address not found" });
      }
      if (loading) return reject({ error: "loading" });
      else setLoading("WAITING FOR SIGNATURE...");

      fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ address: address.toLowerCase() }),
      })
        .then(response => response.json())

        // Popup MetaMask confirmation modal to sign message
        .then(
          response => handleSignMessage(address, response.nonce),
          err => {
            setLoading();
            return reject({ error: "needs to sign" });
          },
        )
        // Send signature to back end on the /auth route
        .then(handleAuthenticate)
        .then(handleLoggedIn)
        .then(s => {
          setLoading();
          if (s.error) return reject({ ...s });
          return resolve({ ...s });
        })
        .catch(err => {
          setLoading();
          return reject({ error: err.toString() });
        });
    });
  };

  const handleSignMessage = (address, nonce) => {
    return new Promise((resolve, reject) => {
      const msg = `Please sign this message to verify your identity.\n\n${nonce}`;
      userSigner
        .signMessage(msg)
        .then(signature => {
          return resolve({ address, signature, method: "mm" });
        })
        .catch(err => {
          console.error(err);
          return reject(err.toString());
        });
    });
  };

  const handleAuthenticate = ({ address, signature, method }) =>
    fetch(`${process.env.REACT_APP_API_URL}/auth`, {
      body: JSON.stringify({ address, signature, method }),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
    }).then(response => response.json());

  const handleLoggedIn = result => {
    if (!result || !result.userData || !result.at || result.error) {
      setLoading();
      return { error: result?.error || "user data not found" };
    } else {
      const userData = result.userData;
      localStorage.setItem("hello:auth", String(result.at));
      localStorage.setItem("hello:exp", String(result.exp));
      if (userData && userData.address) {
        localStorage.setItem("hello:address", String(userData.address));
        setUserData(userData);
      }
      setAccessToken(String(result.at));
      setLoading();
      return { result: "logged in" };
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("hello:auth");
    localStorage.removeItem("hello:exp");
    localStorage.removeItem("hello:address");
    setAccessToken();
    setUserData();
  };

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      //console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      //console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      //console.log(code, reason);
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  useEffect(() => {
    if (userData && currentStep === 0) {
      setCurrentStep(1);
      console.log(currentStep);
    }
  }, [userData, currentStep]);

  return (
    <div className="App">
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <BattlesLogo />
        <BattlesText
          style={{
            height: 150,
            maxWidth: "100%",
            fill: currentTheme === "light" ? "#000000" : "#FFFFFF",
            color: currentTheme === "light" ? "#000000" : "#FFFFFF",
            stroke: currentTheme === "light" ? "#000000" : "#FFFFFF",
          }}
        />
      </div>
      <Hello
        address={address}
        userSigner={userSigner}
        mainnetProvider={mainnetProvider}
        web3Modal={web3Modal}
        userData={userData}
      />
      <Tween
        animation={[
          {
            padding: 40,
            margin: 20,
            boxShadow: "0 0 5px black",
            borderRadius: 15,
            duration: 1000,
            delay: 1000,
            minHeight: 300,
          },
          {
            boxShadow: "0 0 10px black",
            borderRadius: 50,
            duration: 1000,
            minWidth: 800,
          },
        ]}
        style={{
          borderRadius: 5,
          minWidth: 0,
          minHeight: 300,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          border: `3px solid transparent`,
        }}
      >
        {!userData && (
          <FirstStep
            web3Modal={web3Modal}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            accessToken={accessToken}
            handleLogin={handleLogin}
            userData={userData}
            handleLogout={handleLogout}
            userSigner={userSigner}
            address={address}
            mainnetProvider={mainnetProvider}
          />
        )}
        {userData && !userData.discord && (
          <SecondStep userData={userData} userSigner={userSigner} address={address} mainnetProvider={mainnetProvider} />
        )}
        {userData && userData.discord && !userData.twitterChecked && (
          <ThirdStep
            userData={userData}
            userSigner={userSigner}
            address={address}
            refreshToken={refreshToken}
            setUserData={setUserData}
            getUserData={getUserData}
          />
        )}
        {userData && userData.discord && userData.twitterChecked && (
          <Ready userData={userData} address={address} refreshToken={refreshToken} />
        )}
      </Tween>
      <ThemeSwitch />
      {userSigner && accessToken && (
        <div style={{ position: "fixed", right: 8, bottom: 8 }}>
          <LoginButtons
            web3Modal={web3Modal}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            accessToken={accessToken}
            handleLogin={handleLogin}
            userData={userData}
            handleLogout={handleLogout}
          />
        </div>
      )}
    </div>
  );
}

export default App;
